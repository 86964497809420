<script setup lang="ts">
import dayjs from 'dayjs'
import { useQuery } from '@tanstack/vue-query'

import Carousel, { type CarouselRef } from 'ant-design-vue/lib/carousel'
import { withDimInfo } from '~/utils/api/dim'
import { formatNumber, formatNumberWithUnit } from '~/utils/number'
import { goDetail } from '~/utils/common'

import user1 from '~/assets/images/index/user1.png'
import user2 from '~/assets/images/index/user2.png'
import user3 from '~/assets/images/index/user3.png'
import ygh from '~/assets/images/index/杨国辉.png'
import ztj from '~/assets/images/index/张天佳.png'
import hhj from '~/assets/images/index/partner-hhj.png'
import kuaai from '~/assets/images/index/partner-kuaai.png'
import kgj from '~/assets/images/index/partner-kgj.png'
import geelark from '~/assets/images/index/partner-geelark.png'
import quickcep from '~/assets/images/index/partner-quickcep.png'
import znllq from '~/assets/images/index/partner-znllq.png'
import tt123 from '~/assets/images/index/partner-tt123.png'
import zfllq from '~/assets/images/index/partner-zfllq.png'
import ghostcut from '~/assets/images/index/partner-ghostcut.png'
import nmllq from '~/assets/images/index/partner-nmllq.png'
import productIcon from '~/assets/images/index/product.mp4'
import shopIcon from '~/assets/images/index/shop.mp4'
import creatorIcon from '~/assets/images/index/creator.mp4'
import videoIcon from '~/assets/images/index/video.mp4'
import liveIcon from '~/assets/images/index/live.mp4'

definePageMeta({ layout: 'custom', keepalive: false })

const { locale, setLocale, t } = useI18n()
useSeoMeta({
  title: t('website.pageTitle'),
  keywords: t('website.pageKeyword'),
  description: t('website.pageDescription'),
}, { tagPriority: 5 })

const { $request, $getFilterOptions } = useNuxtApp()
const { apiV2 } = useRuntimeConfig().public
const localePath = useLocalePath()
const user = useUser()
const { toggleDialog } = useLogin()

const shortNameKey = useI18nKeys('shortName')
const curLocaleOpt = computed(() => $getFilterOptions().locales.find(({ value }) => locale.value === value))

const menus = [
  // { title: t('领会员'), href: localePath('/') },
  // { title: t('会员购买'), href: localePath('/') },
  // { title: t('帮助文档'), href: localePath('/') },
  { title: t('关于我们'), href: 'https://www.datastory.com.cn/about/companyinfo', target: '_blank' },
]

const counts = ref([
  { name: t('website.新增商品'), total: 782, increase: 782 },
  { name: t('website.新增达人'), total: 490, increase: 490 },
  { name: t('website.新增视频'), total: 1257, increase: 1257 },
  { name: t('website.新增直播'), total: 678, increase: 678 },
])
const now = useNow()
useIntervalFn(() => {
  counts.value.forEach((item) => {
    item.total += item.increase
  })
}, 5 * 1000)

const goItems = [
  { title: 'website.找爆品', url: localePath('/analysis/products'), icon: productIcon, descs: ['热度勘测，把握市场机会', '深度挖掘，复制爆款经验', '捕捉趋势，发掘潜力爆品'] },
  { title: 'website.找小店', url: localePath('/analysis/shop'), icon: shopIcon, descs: ['深度解码，店铺成功秘籍', '精准锚定，甄别热销店铺', '追踪关注，查看店铺走势'] },
  { title: 'website.找达人', url: localePath('/analysis/blogger'), icon: creatorIcon, descs: ['带货分析，链接优质达人', '实时监控，掌握达人动态', '筛选对标，参考起号攻略'] },
  { title: 'website.找视频', url: localePath('/analysis/video'), icon: videoIcon, descs: ['海量视频，获取热点选题', '热门素材，持续内容曝光', '爆款文案，提高创作灵感'] },
  { title: 'website.找直播', url: localePath('/analysis/live'), icon: liveIcon, descs: ['话术拆解，提升讲品转化', '直播标签，筛选对标直播', '全盘数据，直观分析排品'] },
]

const videoRefs = ref<HTMLVideoElement[]>()
function play(i: number) {
  if (videoRefs.value) {
    videoRefs.value[i].play()
  }
}
function stop(i: number) {
  if (videoRefs.value) {
    videoRefs.value[i].pause()
    videoRefs.value[i].currentTime = 0
  }
}

const partnerMap = {
  datasotry: { logo: '', name: t('数说故事'), href: 'https://www.datastory.com.cn' },
  hhj: { logo: hhj, name: t('航海加'), href: 'https://navigate.datastory.com.cn' },
  kuaai: { logo: kuaai, name: 'Kua.ai', href: 'https://www.kua.ai' },
  kgj: { logo: kgj, name: t('考古加'), href: 'https://www.kaogujia.com' },
  geelark: { logo: geelark, name: 'GeeLark', href: 'https://www.geelark.cn/?utm_source=useful-links&utm_medium=partner&utm_campaign=Glodastory' },
  quickcep: { logo: quickcep, name: t('QuickCEP'), href: 'https://www.quickcep.cn' },
  znllq: { logo: znllq, name: t('紫鸟浏览器'), href: 'https://www.ziniao.com/?from=Glodastory&utm_source=Glodastory' },
  tt123: { logo: tt123, name: 'TT123', href: 'https://www.tt123.com' },
  zfllq: { logo: zfllq, name: t('站斧浏览器'), href: 'https://www.zhanfubrowser.com/register?code=FWJM' },
  ghostcut: { logo: ghostcut, name: 'GhostCut', href: 'https://jollytoday.com' },
  nmllq: { logo: nmllq, name: t('牛卖浏览器'), href: 'https://www.hitoor.com/#/Register?invitecode=NQKBHJ' },
  tkwosai: { logo: '', name: 'TKWOSAI', href: 'https://tkwosai.com' },
}

const partners = [
  partnerMap.hhj,
  partnerMap.kuaai,
  partnerMap.kgj,
  partnerMap.geelark,
  partnerMap.quickcep,
  partnerMap.znllq,
  partnerMap.tt123,
  partnerMap.zfllq,
  partnerMap.ghostcut,
  partnerMap.nmllq,
]

const userFeedbacks = [
  { avatar: user1, mark: t('原亚马逊美区卖家'), feedback: t('website.desc1') },
  { avatar: user2, mark: t('原抖音卖家'), feedback: t('website.desc2') },
  { avatar: user3, mark: t('MCN/TAP机构'), feedback: t('website.desc3') },
  { avatar: ztj, mark: t('鬼手剪辑创始人张天佳'), feedback: t('website.desc4') },
  { avatar: ygh, mark: t('航海加业务负责人杨国辉'), feedback: t('website.desc5') },
]

const footerLinks = [
  {
    title: t('关于我们'),
    links: [
      { name: t('关于我们'), href: 'https://www.datastory.com.cn/about/companyinfo' },
      // { name: t('会员权益'), href: localePath('/') },
      // { name: t('用户协议'), href: localePath('/agreement') },
      { name: t('隐私条款'), href: localePath('/privacy') },
    ],
  },
  {
    title: t('产品生态'),
    links: [partnerMap.datasotry, partnerMap.hhj, partnerMap.kgj, partnerMap.kuaai],
  },
  {
    title: t('友情链接'),
    links: [partnerMap.tt123, partnerMap.quickcep, partnerMap.tkwosai, partnerMap.geelark],
  },
  {
    title: t('友情链接'),
    links: [partnerMap.znllq, partnerMap.zfllq, partnerMap.nmllq],
  },
]

/** 鉴权 */
function authenticate() {
  if (!user.value.isLogin) {
    return toggleDialog(true)
  }
  return true
}

watch(() => user.value.isLogin, () => {
  if (user.value.isLogin) {
    goto(localePath('/analysis/products'))
  }
})

function goto(url: string) {
  navigateTo(url)
}

const rankType = ref('products')
const regionId = ref('1')
const carouselRef = ref<CarouselRef>()
const region = computed(() => {
  return user.value.regions.find(r => r.regionId === regionId.value)!
})
const { data: carourselItems, isFetching } = useQuery({
  queryKey: ['/home/ranks', regionId],
  queryFn: async ({ signal }) => {
    const query = {
      regionId: regionId.value,
      dateType: 1,
      dateCode: user.value.dataTimeliness.updateDate,
    }
    const res = await Promise.all([
      withDimInfo($request<Api.TableResponse>('/home/products/hotSale', {
        baseURL: apiV2,
        query,
        signal,
      }).then((res) => {
        res.data.forEach((item) => { item.productId = item.id })
        return res
      }), ['product'], { signal }).then(res => res.data),
      withDimInfo($request<Api.TableResponse>('/home/shops/hotSale', {
        baseURL: apiV2,
        query,
        signal,
      }).then((res) => {
        res.data.forEach((item) => { item.shopId = item.id })
        return res
      }), ['shop'], { signal }).then(res => res.data),
      withDimInfo($request<Api.TableResponse>('/home/bloggers/increaseFans', {
        baseURL: apiV2,
        query,
        signal,
      }).then((res) => {
        res.data.forEach((item) => { item.bloggerId = item.id })
        return res
      }), ['blogger'], { signal }).then(res => res.data),
    ])
    const carourselItems = [
      {
        name: t('热销商品榜'),
        data: res[0].map(item => ({ ...item, cover: item.productData.productCover, title: item.productData.productTitle })).filter(item => item.cover).slice(0, 5),
        href: localePath('/analysis/products/hotSale'),
        type: 'products',
      },
      {
        name: t('热销小店榜'),
        data: res[1].map(item => ({ ...item, cover: item.shopData.shopLogo, title: item.shopData.shopName })).filter(item => item.cover).slice(0, 5),
        href: localePath('/analysis/shop/hotSale'),
        type: 'shop',
      },
      {
        name: t('达人涨粉榜'),
        data: res[2].map(item => ({ ...item, cover: item.bloggerData.avatar, title: item.bloggerData.nickName })).filter(item => item.cover).slice(0, 5),
        href: localePath('/analysis/blogger/increaseFans'),
        type: 'blogger',
      },
    ]

    return carourselItems
  },
})
</script>

<template>
  <a-layout class="relative">
    <a-layout-header class="absolute z-1 h-70 w-full flex items-center gap-40 px-20">
      <NuxtLink :to="localePath('/')">
        <g-svg class="text-[--color-text-1]" name="glodastory-logo" :width="111" :height="32" />
      </NuxtLink>
      <NuxtLink
        v-for="(menu, i) in menus" :key="i"
        class="px-16 text-16 text-[--color-text-2] underline-transparent"
        :to="menu.href" :target="menu.target"
      >
        {{ menu.title }}
      </NuxtLink>
      <div class="flex-1" />

      <g-dropdown-radio
        :model-value="locale"
        :label="curLocaleOpt?.label"
        :show-all="false"
        :options="$getFilterOptions().locales"
        @change="setLocale"
      />
      <LayoutUserAvatar />
    </a-layout-header>

    <a-layout-content class="relative">
      <div class="relative overflow-hidden with-min-h-800">
        <div class="h-full w-full overflow-hidden">
          <video class="block" width="100%" autoplay muted loop>
            <source src="~/assets/images/index/bg1.mp4" type="video/mp4">
          </video>
        </div>

        <div class="absolute left-0 top-0 box-border h-full w-full flex items-center pt-70">
          <div class="ml-7.03% with-min-w-886">
            <h1 class="m-0 text-64 font-600 line-height-90">
              {{ $t('TikTok数据洞察, 引领无限可能') }}
            </h1>
            <p class="mb-0 mt-16 text-24 text-[--color-text-2] line-height-32">
              {{ $t('行业领先的TikTok专业数据服务平台，深度解析2000+细分市场') }}
            </p>
            <a-button
              style="background: linear-gradient(99.82deg, #A266FF 17.49%, #7A34FF 91.26%); color: white;"
              class="mt-32 h-54 px-67 text-24 font-500 rd-200!"
              @click="authenticate() && goto(localePath('/analysis/products'))"
            >
              {{ $t('免费体验') }}
            </a-button>
          </div>
        </div>
      </div>

      <div class="relative min-h-210 text-white">
        <img class="block w-full" src="~/assets/images/index/bg2.jpg">

        <div class="absolute left-0 top-0 h-full w-full">
          <div class="h-full flex items-center justify-center">
            <ul class="relative box-border flex gap-48">
              <div class="absolute left-0 top--39 h-31 flex items-end">
                <span class="w-68 text-20 line-height-28 font-dinpro600">{{ dayjs(now).format('HH:mm:ss') }}</span>
                <span class="mb-2 ml-8 text-12 text-[--color-text-4] line-height-20">{{ dayjs().locale('en').format('MMMM D') }}</span>
              </div>
              <li
                v-for="(count, i) in counts" :key="i"
                class="with-min-w-324"
              >
                <IndexNumberScroll v-model="count.total" line-height="64" class="text-64" />
                <div class="mt-12 text-24 line-height-32">
                  {{ count.name }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ul class="box-border w-full flex items-center justify-between gap-16 px-70 with-min-h-1080">
        <li
          v-for="(item, i) in goItems" :key="i"
          class="go-item block cursor-pointer rd-32 bg-[--color-fill-1]"
          :class="{ 'go-item--en': locale === 'en' }"
          @click="goto(item.url)"
          @mouseenter="play(i)"
          @mouseleave="stop(i)"
        >
          <div class="translate-y--61">
            <video ref="videoRefs" class="block max-w-17.86vw w-full" width="100%" muted loop>
              <source :src="item.icon" type="video/mp4">
            </video>

            <div class="flex justify-center pl-42">
              <a-space direction="vertical" fill :size="40">
                <h1 class="mx-auto my-0 w-full text-48 line-height-56">
                  {{ $t(item.title) }}
                </h1>

                <section class="box-border w-full flex flex-col gap-24 pr-42 text-18 text-[--color-text-3]">
                  <p v-for="(desc, j) in item.descs" :key="j" class="m-0 line-height-26">
                    {{ $t(desc) }}
                  </p>
                </section>

                <a-button
                  class="rd-50 px-24 text-[--color-text-2]"
                  style="color: var(--color-text-2); border-color: var(--color-text-2);"
                  status="normal" type="outline"
                >
                  <g-svg name="right" :width="16" :height="16" />
                </a-button>
              </a-space>
            </div>
          </div>
        </li>
      </ul>

      <div class="relative min-h-810">
        <img class="block h-full w-full" src="~/assets/images/index/bg3.jpg">

        <div class="absolute left-0 top-0 h-full w-full flex flex-col items-center">
          <a-skeleton v-if="isFetching" class="with-min-h-852" animation>
            <div class="my-5.42%">
              <a-skeleton-line :line-height="42" />
            </div>

            <ul class="flex gap-16">
              <li
                v-for="n in 5" :key="n"
                class="relative box-border flex flex-col overflow-hidden rd-24 bg-white with-min-h-470 with-min-w-343"
              >
                <a-skeleton-shape class="mb-20 w-full with-min-h-324" />
                <div class="ml-20">
                  <a-skeleton-line :widths="['80%', '80%', '80%']" :rows="3" />
                </div>
              </li>
            </ul>
          </a-skeleton>

          <Carousel v-else ref="carouselRef" class="w-full" :dots="false">
            <div
              v-for="(carourselItem, i) in carourselItems" :key="i"
              class="flex-col items-center flex!"
            >
              <h1 class="my-5.42% text-center text-64 font-600 line-height-90">
                {{ carourselItem.name }}
              </h1>
              <ul class="flex gap-16">
                <li
                  v-for="(item, j) in carourselItem.data ?? []" :key="item.id"
                  class="relative box-border flex flex-col cursor-pointer overflow-hidden rd-24 bg-white with-min-h-470 with-min-w-343"
                  @click="goDetail(carourselItem.type as 'products', item.id)"
                >
                  <div
                    class="absolute left-0 top-0 z-1 size-48 flex items-center justify-center rd-50% bg-black text-24 text-white font-dinpro600"
                    :class="{ 'opacity-50%': j >= 3 }"
                  >
                    {{ j + 1 }}
                  </div>
                  <a-image
                    width="100%"
                    class="overflow-hidden with-min-h-324"
                    fit="cover"
                    :preview="false"
                    :src="item.cover"
                    :alt="item.title"
                  />
                  <div class="box-border w-full flex flex-1 flex-col justify-center px-30 text-[--color-text-2]">
                    <div class="flex flex-col justify-between with-min-h-86">
                      <div class="h-22 flex-shrink-0 line-height-22 ellipsis">
                        {{ item.title }}
                      </div>
                      <TableColumnPrice
                        v-if="item.productData"
                        class="mt-3.5% h-24"
                        :min="item.productData.minSkuPrice"
                        :max="item.productData.maxSkuPrice"
                        :sku-price="item.productData.skuPrice"
                        :unit="item.productData.currencySymbol"
                      />
                      <div v-if="item.shopData" class="mt-3.5% line-height-22">
                        <span class="text-[--color-text-3]">{{ $t('店铺评分') }}</span>
                        <span class="ml-4 font-dinpro500">{{ formatNumberWithUnit(item.shopData.shopRating) }}</span>
                      </div>
                      <div v-if="item.bloggerData" class="mt-12 line-height-22">
                        <span class="text-[--color-text-3]">{{ $t('粉丝') }}</span>
                        <span class="ml-4 font-dinpro500">{{ formatNumber(item.bloggerData.followerCount)[0] }}</span>
                        {{ formatNumber(item.bloggerData.followerCount)[1] }}
                      </div>
                      <div class="mt-6 h-22 flex">
                        <span class="text-[--color-text-3]">{{ item.bloggerData ? $t('新增粉丝') : $t('新增销量') }}</span>
                        <span class="ml-4 font-dinpro600">{{ formatNumber(item.count)[0] }}</span>
                        {{ formatNumber(item.count)[1] }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <!-- <a-button
                class="mt-2.19% h-42 rd-50 px-24 text-[--color-text-2] hover:b-[--color-brand-6]! hover:bg-[--color-brand-6]! hover:text-white!"
                style="color: var(--color-text-2); border-color: var(--color-text-2);"
                status="normal" type="outline"
                @click="goto(carourselItem.href)"
              >
                MORE
                <g-svg name="right" class="ml-4" :width="16" :height="16" />
              </a-button> -->
            </div>
          </Carousel>

          <div class="mt-2.5% box-border h-70 flex items-center justify-between rd-50 bg-white px-16">
            <g-dropdown-radio
              v-model="regionId"
              position="bottom"
              :show-all="false"
              :popup-max-height="false"
              :trigger-props="{ unmountOnClose: false }"
              :options="user.regions.map(region => ({ label: region[shortNameKey], value: region.regionId, flag: region.regionFlag }))"
              @change="rankType = 'products'"
            >
              <template #label="opt">
                <a-space :size="4">
                  <img class="size-16" :src="opt.flag">{{ opt.label }}
                </a-space>
              </template>
              <div v-if="region" class="h-38 bg-[--color-fill-1] px-24 g-radio-btn">
                <img class="mr-4 size-16" :src="region.regionFlag">
                {{ region[shortNameKey] }} <icon-down />
              </div>
            </g-dropdown-radio>
            <a-space :size="10" class="ml-64">
              <div
                v-for="(rank, i) in [
                  { name: $t('商品'), type: 'products' },
                  { name: $t('小店'), type: 'shop' },
                  { name: $t('达人'), type: 'blogger' },
                ]" :key="i"
                class="h-38 bg-transparent px-48 g-radio-btn"
                :class="{ 'g-radio-btn--checked bg-[--color-brand-1]!': rankType === rank.type }"
                @click="rankType = rank.type; carouselRef?.goTo(i)"
              >
                {{ rank.name }}
              </div>
            </a-space>
          </div>
        </div>
      </div>

      <div class="h-480 flex flex-col items-center">
        <h1 class="mb-0 mt-104 text-64 font-600 line-height-90">
          {{ $t('合作伙伴') }}
        </h1>
        <div class="scroll-wrapper mt-78 w-1200 overflow-hidden">
          <ul class="partners flex gap-15">
            <li v-for="(partner, i) in [...partners, ...partners]" :key="i">
              <NuxtLink class="inline-block px-14 py-18" :href="partner.href" target="_blank">
                <img :src="partner.logo" class="h-48">
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div class="h-780 flex flex-col items-center bg-[--color-fill-1]">
        <h1 class="my-104 text-64 font-600 line-height-90">
          {{ $t('用户寄语') }}
        </h1>

        <Carousel class="w-1296 overflow-hidden rd-239" arrows :dots="false">
          <div
            v-for="(userFeedback, i) in userFeedbacks" :key="i"
            class="feedback-item h-372 w-full items-center justify-center rd-239 flex!"
          >
            <div class="h-236 flex gap-76">
              <img width="236" height="236" class="rd-50%" style="object-fit: cover;" :src="userFeedback.avatar" :alt="userFeedback.mark" srcset="">
              <section class="h-220 w-556 flex flex-col justify-between">
                <div>
                  <img src="~/assets/images/index/quotation.png" class="size-60">
                  <p class="mb-0 mt-12">
                    {{ userFeedback.feedback }}
                  </p>
                </div>
                <div class="h-24 flex gap-10">
                  <a-divider class="m-0 inline-block min-w-auto w-auto flex-1 translate-y--5 b-b-[--color-fill-4]" />
                  <span class="flex-shrink-0 text-16 font-500">{{ userFeedback.mark }}</span>
                </div>
              </section>
            </div>
          </div>
          <template #prevArrow>
            <a-button
              class="z-1 text-[--color-text-2] hover:b-[--color-brand-6]! hover:bg-[--color-brand-6]! hover:text-white!"
              style="left: 56px; height: 42px; width: 42px; color: var(--color-text-2); border-color: var(--color-text-2);"
              shape="circle" type="outline"
            >
              <g-svg name="left" :width="16" :height="16" />
            </a-button>
          </template>
          <template #nextArrow>
            <a-button
              class="text-[--color-text-2] hover:b-[--color-brand-6]! hover:bg-[--color-brand-6]! hover:text-white!"
              style="right: 56px; height: 42px; width: 42px; color: var(--color-text-2); border-color: var(--color-text-2);"
              shape="circle" type="outline"
            >
              <g-svg name="right" :width="16" :height="16" />
            </a-button>
          </template>
        </Carousel>

        <!-- <a-space class="mt-70 items-start" :size="100">
          <g-svg name="datastory-logo" class="text-[--color-text-4]" :width="260" :height="68" />
          <section class="w-650">
            <h3 class="m-0 text-24 text-[--color-text-1] font-500 line-height-32">
              {{ $t('社媒营销数智化领跑者') }}
            </h3>
            <p class="mb-0 mt-8 text-[--color-text-2] line-height-22">
              {{ $t('website.desc6') }}
            </p>

            <NuxtLink
              class="mt-46 h-42 inline-flex items-center rd-50 px-24 text-[--color-text-2] hover:b-[--color-brand-6] hover:bg-[--color-brand-6] hover:text-white"
              b="1 solid [--color-text-2]"
              href="https://www.datastory.com.cn/about/companyinfo" target="_blank"
            >
              MORE
              <g-svg name="right" class="ml-4" :width="16" :height="16" />
            </NuxtLink>
          </section>
        </a-space> -->
      </div>
    </a-layout-content>

    <a-layout-footer class="relative h-22.71vw min-h-327 flex flex-col items-center bg-[#181818]">
      <div v-if="locale === 'zh'" class="mt-5.73% flex items-center">
        <div>
          <g-svg name="glodastory-logo" class="text-white" :width="111" :height="32" />
          <a-space class="mt-30" :size="16" fill>
            <div class="box-border w-100 rd-8 bg-white p-4">
              <img src="~/assets/images/index/小程序.png" class="mx-auto block w-76" alt="Gloda 小程序">
              <div class="mt-4 whitespace-break-spaces text-center text-12 font-500 line-height-16">
                Gloda {{ $t('小程序') }}
              </div>
            </div>
            <div class="box-border w-100 rd-8 bg-white p-4">
              <img src="~/assets/images/index/Gloda服务号.png" class="mx-auto block h-76 w-76" alt="Gloda 服务号">
              <div class="mt-4 whitespace-break-spaces text-center text-12 font-500 line-height-16">
                Gloda {{ $t('服务号') }}
              </div>
            </div>
            <div class="box-border w-100 rd-8 bg-white p-4">
              <img src="~/assets/images/index/客服号.png" class="mx-auto block w-76" alt="产品咨询">
              <div class="mt-4 whitespace-break-spaces text-center text-12 font-500 line-height-16">
                {{ $t('产品咨询') }}
              </div>
            </div>
          </a-space>
        </div>

        <div class="ml-136 flex gap-127">
          <section v-for="(group, i) in footerLinks" :key="i">
            <span class="block text-16 text-white font-500 line-height-24">{{ group.title }}</span>
            <a-space class="mt-28 text-12 text-[--color-text-4]" direction="vertical">
              <NuxtLink
                v-for="(link, j) in group.links" :key="j"
                :href="link.href" target="_blank"
                class="text-[--color-text-4] line-height-20 active:text-[--color-text-3] hover:text-white"
              >
                {{ link.name }}
              </NuxtLink>
            </a-space>
          </section>
        </div>
      </div>
      <div v-else class="mt-5.73%">
        <g-svg name="glodastory-logo" class="text-white" :width="139" :height="37" />

        <div class="mt-32 flex gap-160">
          <section v-for="(group, i) in footerLinks" :key="i">
            <span class="block text-16 text-white font-500 line-height-24">{{ group.title }}</span>
            <a-space class="mt-28 text-12 text-[--color-text-4]" direction="vertical">
              <NuxtLink
                v-for="(link, j) in group.links" :key="j"
                :href="link.href" target="_blank"
                class="text-[--color-text-4] line-height-20 active:text-[--color-text-3] hover:text-white"
              >
                {{ link.name }}
              </NuxtLink>
            </a-space>
          </section>
        </div>
      </div>

      <div v-if="locale === 'zh'" class="absolute bottom-24 h-20 flex items-center text-12 text-[--color-text-2]">
        <img class="mr-2 size-16" src="~/assets/images/index/police.png">
        <a href="https://beian.miit.gov.cn/" target="_blank" class="mr-4 text-[--color-text-2]">粤ICP备15073179号-4</a>Copyright © {{ dayjs().format('YYYY') }} 广州数说故事信息科技有限公司 All Rights Reserved 版权所有
      </div>
    </a-layout-footer>
  </a-layout>
</template>

<style lang="less" scoped>
@media (max-width: 1860px) {
  .go-item {
    h1 {
      @apply 'text-32 line-height-40';
    }

    section {
      @apply 'pr-32';

      p {
        @apply 'text-14 line-height-22';
      }
    }

    & > div > div {
      @apply 'pl-32';
    }

    &--en {
      h1 {
        @apply 'h-80';
      }

      section {
        @apply 'h-224';
      }
    }
  }
}

@media (min-width: 1860px) {
  .go-item {
    &--en {
      h1 {
        @apply 'h-112';
      }

      section {
        @apply 'h-204';
      }
    }
  }
}

.go-item {
  &:hover {
    :deep(.arco-btn) {
      @apply 'text-white! b-[--color-brand-6]! bg-[--color-brand-6]!';
    }
  }
}

.feedback-item {
  background: url(~/assets/images/index/bg4.png) 637px bottom / 466px 295px no-repeat, white;
}

@keyframes scrollleft {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-2432px, 0, 0);
  }
}
.scroll-wrapper {
  .partners {
    animation: 30s scrollleft linear infinite;

    &:hover {
      animation-play-state: paused;
    }
  }
}
</style>
