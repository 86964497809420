<script setup lang="ts">
withDefaults(defineProps<{
  lineHeight?: number | string
  fontClass?: string
}>(), {
  lineHeight: 22,
})

const modelValue = defineModel<number>({ default: 0 })

const nums = computed(() => {
  return modelValue.value.toString().split('').map(n => Number(n))
})

function handleTransitionEnd(ev: TransitionEvent, n: number) {
  if (n === 9) {
    const dom = ev.target as HTMLElement
    dom.style.transitionDuration = '0ms'
    dom.style.transform = 'translate3d(0, 0, 0)'
    setTimeout(() => {
      dom.style.transitionDuration = '600ms'
    }, 0)
  }
}
</script>

<template>
  <div
    :style="{ height: `${lineHeight}px` }"
    class="inline-flex overflow-hidden"
  >
    <ul
      v-for="(num, i) in nums" :key="i"
      class="transition-transform-600 font-dinpro600" :class="fontClass"
      :style="{
        'line-height': `${lineHeight}px`,
        'transform': `translate3d(0, -${(num + 1) * Number(lineHeight)}px, 0)`,
      }"
      @transitionend="handleTransitionEnd($event, num)"
    >
      <li>9</li>
      <li>0</li>
      <li>1</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
      <li>5</li>
      <li>6</li>
      <li>7</li>
      <li>8</li>
      <li>9</li>
    </ul>
  </div>
</template>

<style scoped>
</style>
